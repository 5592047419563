export const Langs = [
  'en',
  'de',
  'pt',
  'es',
];

export function getLang() {
  return window.getGameLang ? window.getGameLang() : 'en';
}

export function setLang(lang) {
  window.setGameLang && window.setGameLang(lang);
  window.location.reload();
}
