import { getLang } from '../lang';

const storeBadge = {
  ios: (lang) => `img/app_store_badge_${lang}.svg`,
  android: (lang) => `img/google_play_badge_${lang}.png`,
};

/**
 * @param {{ store: "ios"|"android" }} props 
 */
export default function DownloadButton(props) {
  const { store } = props;
  const lang = getLang();

  return (
    <a className='store-badge' target='_blank' href={window.getInstallUrl()}>
      <img className='store-badge-img' src={storeBadge[store](lang)} />
    </a>
  );
}
