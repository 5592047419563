import { getLang } from '../lang';
import de from './labels-de.json';
import en from './labels-en.json';
import pt from './labels-pt.json';
import es from './labels-es.json';

const langLabels = {
  de,
  en,
  pt,
  es,
};

const numbers = {
  de: singularPluralNumber,
  en: singularPluralNumber,
  pt: singularPluralNumber,
  es: singularPluralNumber,
};

export const Labels = {
  PageTitle: new Label('page_title'),
  Footer: new Label('footer'),
  PlayNow: new Label('play_now'),
};

function Label(key) {
  this.key = key;

  this.getValue = (args = undefined, countBy = undefined) => {
    const lang = getLang();
    var actualKey = key;

    if (countBy !== undefined) {
      actualKey = key + '.' + numbers[lang](countBy);
    }

    var label = langLabels[lang][actualKey] || actualKey;

    if (args) {
      Object.entries(args).forEach(
        ([key, value]) => label = label.replaceAll(`\$\{${key}\}`, value));
    }

    return label;
  };
}

function singularPluralNumber(count) {
  if (count === 1) {
    return 'singular';
  }

  return 'plural';
}