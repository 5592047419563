import Grid from '@mui/material/Grid';
import DownloadButton from './DownloadButton';
import { Labels } from '../labels';
import { getLang } from '../lang';

export default function GameList() {
  const lang = getLang();

  const renderGameCard = (game, index) => {
    if (!isGameAvailable(game)) {
      return null;
    }

    return (
      <Grid key={index} item xs={12} md={6} lg={4}>
        <div className='game-list-item-paper'>
          <a className='game-list-item' href={getGameProperty(game.gameUrl)}>
            <img className='game-list-item-img' src={getGameProperty(game.imageUrl)} />
            <div className='game-list-item-info'>
              <div className='game-list-item-title'>{getGameProperty(game.title)}</div>
              <div className='game-list-item-desc'>{getGameProperty(game.description)}</div>
              <div className='game-list-item-play'>{Labels.PlayNow.getValue()}</div>
            </div>
          </a>
        </div>
      </Grid>
    );
  };

  const getGameProperty = (property) => {
    return property ? property[lang] || property : null;
  };

  const isGameAvailable = (game) => {
    return game.available === null ||
      game.available === undefined ||
      (Array.isArray(game.available) && game.available.includes(lang));
  };

  return (
    <div className='game-list'>
      <div className='game-list-content'>
        <Grid container spacing={2.5} alignItems='stretch'>
          {window.games.map(renderGameCard)}

          <Grid item xs={12} md={6} lg={4}>
            <div className='game-list-item-paper ep-card'>
              <img className='ep-card-logo' src={`img/ep_logo_${lang}_dm.png`} />

              <div className='store-badge-container'>
                <DownloadButton store='ios' />
                <DownloadButton store='android' />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
