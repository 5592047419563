import './App.css';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Footer from './components/Footer';
import GameList from './components/GameList';
import CssBaseline from '@mui/material/CssBaseline';
import { getLang } from './lang';
import { Labels } from './labels';

function App() {
  const lang = getLang();

  return (
    <>
      <Helmet>
        <html lang={lang} />
        <title>{Labels.PageTitle.getValue()}</title>
      </Helmet>

      <div className='app'>
        <CssBaseline />
        <Header />
        <GameList />
        <Footer />
      </div>
    </>
  );
}

export default App;
