import Box from '@mui/material/Box';
import DownloadButton from './DownloadButton';
import { getLang } from '../lang';

export default function Header() {
  const lang = getLang();

  return (
    <div className='header'>

      <Box className='banner' component='img'
        sx={{
          content: {
              xs: `url(img/banner-mobile.png)`,
              sm: `url(img/banner.png)`,
          }
        }}
      />

      <img className='header-logo' src={`img/ep_logo_${lang}.png`} />

      <div className='store-badge-container'>
        <DownloadButton store='ios' />
        <DownloadButton store='android' />
      </div>
    </div>
  );
}
